import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./style.css";
import Navbar from "./NavBar";
import Hero from "./Hero";
import Project from "./Projects";
import Contact from "./Contact";
import Footer from "./Footer";
import RotatingCube from "./Skills";
function App() {
  return (
    <Router>
      <div className="container-fluid p-0 m-0 app position-relative">
        <Navbar />
        <Hero />
        <Project />
        <Contact />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
