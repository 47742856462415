import { motion } from "framer-motion";
import man from "./img/man.webp";
import el from "./img/el.png";
import AutomaticLetterTyping from "./Autotyping";

const Hero = () => {
  const icon = {
    hidden: {
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      pathLength: 1,
      fill: "rgba(255, 255, 255, 1)",
    },
  };
  const iconlinkedin = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
    },
  };
  return (
    <motion.div className="position-relative">
      <div
        className="p-0 m-0 position-relative hero-section container-fluid"
        id="home"
      >
        <div className="container px-5">
          <div className="row">
            <motion.div className="p-4 mt-5 overflow-hidden rounded-5 col-md-6 d-flex flex-column">
              <span className="text-primary neon-text fs-5 fs-md-4 fs-3">
                <b>Hi!</b>
              </span>
              <motion.span className="text-black neon-text fs-5 fs-md-4 fs-3">
                <b>
                  I'm <span className="text-primary">VIKASH KUMAR</span>
                </b>
              </motion.span>
              <div className="">
                <span className="neon-text text-light fs-5 fs-md-5 fs-4 d-flex">
                  <b className="me-1 text-dark">
                    <b>Skills</b>
                  </b>
                  <b>
                    <AutomaticLetterTyping />
                  </b>
                </span>
              </div>
              <div className="d-flex justify-content-start my-2 align-items-center">
                <a
                  href="https://www.linkedin.com/in/vikash-kumar-bishnoi"
                  target="_blank"
                  referrer="no-referrer"
                  className=""
                >
                  <motion.svg
                    fill="#ffff"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    className="itemmini"
                  >
                    <g id="SVGRepo_iconCarrier">
                      <motion.path
                        variants={iconlinkedin}
                        initial="hidden"
                        animate="visible"
                        transition={{
                          default: { duration: 3, ease: "easeInOut" },
                          fill: { duration: 3, ease: [1, 0, 0.8, 1] },
                        }}
                        d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z"
                      ></motion.path>
                    </g>
                  </motion.svg>
                </a>
                <a
                  href="https://twitter.com/kvbishnoi29"
                  target="_blank"
                  referrer="no-referrer"
                  className="mx-2"
                >
                  <motion.svg
                    fill="#ffff"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="itemmini itemminitwitter"
                    viewBox="0 0 16 16"
                  >
                    <motion.path
                      variants={iconlinkedin}
                      initial="hidden"
                      animate="visible"
                      transition={{
                        default: { duration: 3, ease: "easeInOut" },
                        fill: { duration: 3, ease: [1, 0, 0.8, 1] },
                      }}
                      d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
                    />
                  </motion.svg>
                </a>
                <a
                  href="https://github.com/IDOL-DEVELOPER"
                  target="_blank"
                  referrer="no-referrer"
                  className="mx-2"
                >
                  <motion.svg
                    fill="#ffff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="itemmini itemminitwitter"
                  >
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <motion.path
                        variants={iconlinkedin}
                        initial="hidden"
                        animate="visible"
                        transition={{
                          default: { duration: 3, ease: "easeInOut" },
                          fill: { duration: 3, ease: [1, 0, 0.8, 1] },
                        }}
                        d="M4.0744 2.9938C4.13263 1.96371 4.37869 1.51577 5.08432 1.15606C5.84357 0.768899 7.04106 0.949072 8.45014 1.66261C9.05706 1.97009 9.11886 1.97635 10.1825 1.83998C11.5963 1.65865 13.4164 1.65929 14.7213 1.84164C15.7081 1.97954 15.7729 1.97265 16.3813 1.66453C18.3814 0.651679 19.9605 0.71795 20.5323 1.8387C20.8177 2.39812 20.8707 3.84971 20.6494 5.04695C20.5267 5.71069 20.5397 5.79356 20.8353 6.22912C22.915 9.29385 21.4165 14.2616 17.8528 16.1155C17.5801 16.2574 17.3503 16.3452 17.163 16.4167C16.5879 16.6363 16.4133 16.703 16.6247 17.7138C16.7265 18.2 16.8491 19.4088 16.8973 20.4002C16.9844 22.1922 16.9831 22.2047 16.6688 22.5703C16.241 23.0676 15.6244 23.076 15.2066 22.5902C14.9341 22.2734 14.9075 22.1238 14.9075 20.9015C14.9075 19.0952 14.7095 17.8946 14.2417 16.8658C13.6854 15.6415 14.0978 15.185 15.37 14.9114C17.1383 14.531 18.5194 13.4397 19.2892 11.8146C20.0211 10.2698 20.1314 8.13501 18.8082 6.83668C18.4319 6.3895 18.4057 5.98446 18.6744 4.76309C18.7748 4.3066 18.859 3.71768 18.8615 3.45425C18.8653 3.03823 18.8274 2.97541 18.5719 2.97541C18.4102 2.97541 17.7924 3.21062 17.1992 3.49805L16.2524 3.95695C16.1663 3.99866 16.07 4.0147 15.975 4.0038C13.5675 3.72746 11.2799 3.72319 8.86062 4.00488C8.76526 4.01598 8.66853 3.99994 8.58215 3.95802L7.63585 3.49882C7.04259 3.21087 6.42482 2.97541 6.26317 2.97541C5.88941 2.97541 5.88379 3.25135 6.22447 4.89078C6.43258 5.89203 6.57262 6.11513 5.97101 6.91572C5.06925 8.11576 4.844 9.60592 5.32757 11.1716C5.93704 13.1446 7.4295 14.4775 9.52773 14.9222C10.7926 15.1903 11.1232 15.5401 10.6402 16.9905C10.26 18.1319 10.0196 18.4261 9.46707 18.4261C8.72365 18.4261 8.25796 17.7821 8.51424 17.1082C8.62712 16.8112 8.59354 16.7795 7.89711 16.5255C5.77117 15.7504 4.14514 14.0131 3.40172 11.7223C2.82711 9.95184 3.07994 7.64739 4.00175 6.25453C4.31561 5.78028 4.32047 5.74006 4.174 4.83217C4.09113 4.31822 4.04631 3.49103 4.0744 2.9938Z"
                        fill="#0F0F0F"
                      ></motion.path>{" "}
                      <path d="M3.33203 15.9454C3.02568 15.4859 2.40481 15.3617 1.94528 15.6681C1.48576 15.9744 1.36158 16.5953 1.66793 17.0548C1.8941 17.3941 2.16467 17.6728 2.39444 17.9025C2.4368 17.9449 2.47796 17.9858 2.51815 18.0257C2.71062 18.2169 2.88056 18.3857 3.05124 18.5861C3.42875 19.0292 3.80536 19.626 4.0194 20.6962C4.11474 21.1729 4.45739 21.4297 4.64725 21.5419C4.85315 21.6635 5.07812 21.7352 5.26325 21.7819C5.64196 21.8774 6.10169 21.927 6.53799 21.9559C7.01695 21.9877 7.53592 21.998 7.99999 22.0008C8.00033 22.5527 8.44791 23.0001 8.99998 23.0001C9.55227 23.0001 9.99998 22.5524 9.99998 22.0001V21.0001C9.99998 20.4478 9.55227 20.0001 8.99998 20.0001C8.90571 20.0001 8.80372 20.0004 8.69569 20.0008C8.10883 20.0026 7.34388 20.0049 6.67018 19.9603C6.34531 19.9388 6.07825 19.9083 5.88241 19.871C5.58083 18.6871 5.09362 17.8994 4.57373 17.2891C4.34391 17.0194 4.10593 16.7834 3.91236 16.5914C3.87612 16.5555 3.84144 16.5211 3.80865 16.4883C3.5853 16.265 3.4392 16.1062 3.33203 15.9454Z"></path>{" "}
                    </g>
                  </motion.svg>
                </a>
                <a
                  href="https://stackoverflow.com/users/22743416/vikash-kumar-bishnoi"
                  target="_blank"
                  referrer="no-referrer"
                  className="mx-2"
                >
                  <motion.svg
                    fill="#ffff"
                    viewBox="0 0 24 24"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    className="itemmini itemminitwitter"
                  >
                    <g id="SVGRepo_iconCarrier">
                      <motion.path
                        variants={iconlinkedin}
                        initial="hidden"
                        animate="visible"
                        transition={{
                          default: { duration: 3, ease: "easeInOut" },
                          fill: { duration: 3, ease: [1, 0, 0.8, 1] },
                        }}
                        d="M18.986 21.865v-6.404h2.134V24H1.844v-8.539h2.13v6.404h15.012zM6.111 19.731H16.85v-2.137H6.111v2.137zm.259-4.852l10.48 2.189.451-2.07-10.478-2.187-.453 2.068zm1.359-5.056l9.705 4.53.903-1.95-9.706-4.53-.902 1.936v.014zm2.715-4.785l8.217 6.855 1.359-1.62-8.216-6.853-1.35 1.617-.01.001zM15.751 0l-1.746 1.294 6.405 8.604 1.746-1.294L15.749 0h.002z"
                      ></motion.path>
                    </g>
                  </motion.svg>
                </a>
              </div>
              <a className="p-2 svg-icon-main d-flex justify-content-center">
                <motion.svg
                  viewBox="0 0 24 24"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke=""
                  className="item"
                >
                  <g id="SVGRepo_iconCarrier">
                    <motion.path
                      variants={icon}
                      initial="hidden"
                      animate="visible"
                      transition={{
                        default: { duration: 5, ease: "easeInOut" },
                        fill: { duration: 5, ease: [1, 0, 0.8, 1] },
                      }}
                      d="M23.474,0.159L17.08,0.775c-0.406,0.039-0.844,0.383-0.978,0.768l-4.092,11.749L7.898,1.542 C7.764,1.158,7.325,0.814,6.92,0.775L0.526,0.159C0.121,0.12-0.096,0.399,0.041,0.783L8.085,23.15 c0.138,0.383,0.581,0.695,0.988,0.695h6.223h0.039c0.073,0,0.134-0.02,0.179-0.055c0.124-0.062,0.231-0.169,0.275-0.292 l0.039-0.108l8.13-22.607C24.096,0.399,23.879,0.12,23.474,0.159z"
                    ></motion.path>
                  </g>
                </motion.svg>
              </a>
            </motion.div>
            <motion.div className="col-md-6 hero-img d-flex justify-content-lg-end justify-content-center position-relative">
              <motion.img
                src={el}
                alt="Background"
                className="position-absolute"
                style={{ transform: `translate(${50}px, ${49}px)` }}
              />
              <motion.img
                src={man}
                alt="Hero Image"
                className="hero-img "
                style={{
                  backgroundAttachment: "fixed",
                }}
                whileTap={{ scale: 1.01 }}
                transition={{ duration: 0.5 }}
                // drag
                // dragConstraints={{
                //   top: -6,
                //   left: -6,
                //   right: 6,
                //   bottom: 0,
                // }}
              />
            </motion.div>
          </div>
        </div>
      </div>
      <div className="px-4" id="about">
        <hr className="" />
      </div>
      <div className="bg-attactment-fix">
        <span className="text-primary px-4 text-uppercase text-underline">
          <b> About Me</b>
        </span>
        <p className="px-4 mt-3">
          I am a passionate full-stack web developer with expertise in various
          technologies, including{" "}
          <b className="text-primary">PHP, jQuery, Bootstrap, SQL, and React</b>
          . I have undergone training at a{" "}
          <b className="text-primary">THE CODING SCHOOL</b>, where I honed my
          skills to become a proficient and skilled web developer. My goal is to
          explore new and challenging projects, continuously improving and
          reaching new heights in my field. I am committed to writing code that
          is innovative and of high quality, and I strive to understand and
          embrace new and better ways in the ever-evolving field of web
          development. <br />
          In addition to my formal training, I bring 3 months of practical
          experience, during which I successfully completed live projects. This
          hands-on exposure has not only strengthened my technical abilities but
          also enhanced my problem-solving skills in real-world scenarios. I am
          committed to writing innovative and high-quality code, and I strive to
          understand and embrace new and better ways in the ever-evolving field
          of web development.
        </p>
      </div>
    </motion.div>
  );
};
export default Hero;
