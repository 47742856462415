import { motion, useScroll } from "framer-motion";
import { useState, useEffect, useRef } from "react";

const Navbar = () => {
  const variants = {
    open: { opacity: 1, x: 0, duration: 0.5, ease: "easeInOut" },
    closed: { opacity: 0, x: "-100%" },
  };
  const ref = useRef(null);

  const variantitem = {
    open: {
      ref: ref,
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 5000, duration: 0.5, velocity: -500 },
      },
    },
    closed: {
      ref: ref,
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 5000, duration: 0.5, velocity: 500 },
      },
    },
  };

  const { scrollYProgress } = useScroll();
  const handleScrollTohome = () => {
    const aboutElement = document.getElementById("home");
    aboutElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleScrollToAbout = () => {
    const aboutElement = document.getElementById("about");
    aboutElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleScrollToPoject = () => {
    const aboutElement = document.getElementById("project");
    aboutElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  //
  useEffect(() => {
    const appDiv = document.getElementById("blur-effect-body");
    if (appDiv) {
      if (isOpen) {
        appDiv.classList.add("blur-effect-body-open");
      } else {
        appDiv.classList.remove("blur-effect-body-open");
      }
    }
  }, [isOpen]);

  return (
    <>
      <header className="position-fixed w-100">
        <div className="px-2">
          <button
            className={`btn bg-none d-lg-none rounded-50 px-2 position-absolute  navbtn ${
              isOpen ? "" : "shadow bg-primary"
            } `}
            style={{ border: "none" }}
            onClick={toggleNav}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="none"
              strokeLinejoin="none"
              style={{
                width: "24px",
                height: "24px",
                display: "block",
                margin: "auto",
              }}
            >
              <motion.line
                x1="3"
                y1="6"
                x2="21"
                y2="6"
                animate={isOpen ? { opacity: 1, x: -50 } : { opacity: 1, x: 0 }}
              />
              <motion.line
                x1="3"
                y1="12"
                x2="21"
                y2="12"
                animate={isOpen ? { opacity: 1, x: 50 } : { opacity: 1, x: 0 }}
              />
              <motion.line
                x1="3"
                y1="18"
                x2="21"
                y2="18"
                animate={isOpen ? { opacity: 1, x: 50 } : { opacity: 1, x: 0 }}
              />
              <motion.line
                x1="18"
                y1="6"
                x2="6"
                y2="18"
                animate={
                  !isOpen ? { opacity: 0, x: -50 } : { opacity: 1, x: 0 }
                }
              />
              <motion.line
                x1="6"
                y1="6"
                x2="18"
                y2="18"
                animate={!isOpen ? { opacity: 0, x: 50 } : { opacity: 1, x: 0 }}
              />
            </svg>
          </button>
        </div>
        {/* desktop nav */}
        <motion.nav className="col-12 shadow bg-primary d-none d-lg-block position-sticky top-0">
          <div className="d-flex w-100 justify-content-between align-items-center px-2 py-3">
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.5 }}>
              <span className="text-light text-uppercase pointer neon-text h-100">
                <b>&lt;Vikash Kumar/&gt;</b>
              </span>
            </motion.div>
            <div className="d-flex align-items-center">
              <ol className="d-flex align-itmes-center m-0 p-0 justify-content-between">
                <motion.li className="nav-item mx-2">
                  <motion.a
                    href="#"
                    className="neon-text text-light text-uppercase"
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={handleScrollTohome}
                  >
                    HOME
                  </motion.a>
                </motion.li>
                <motion.li className="mx-2">
                  <motion.a
                    href="#about"
                    className="neon-text text-light text-uppercase"
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={handleScrollToAbout}
                  >
                    About
                  </motion.a>
                </motion.li>
                <motion.li className="mx-2">
                  <motion.a
                    href="#project"
                    className="neon-text text-light text-uppercase"
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={handleScrollToPoject}
                  >
                    portfolio
                  </motion.a>
                </motion.li>
                <motion.li className="mx-2">
                  <motion.a
                    href="#contact"
                    className="neon-text text-light text-uppercase"
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    Contact
                  </motion.a>
                </motion.li>
              </ol>
            </div>
          </div>
        </motion.nav>
        {/* mobile nav */}
        <motion.nav
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          className={`col-md-4 col-8 d-lg-none h-100 position-fixed text-light`}
        >
          <motion.div
            className="p-2 h-100 position-relative bg-bl-transparent py-5 shadow"
            animate={isOpen ? "open" : "closed"}
            variants={variants}
          >
            <motion.ul className="">
              <motion.li
                ref={ref}
                variants={variantitem}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a href="#" className="neon-text text-light text-uppercase">
                  HOME
                </a>
              </motion.li>
              <motion.li
                ref={ref}
                variantitem={variantitem}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a
                  href="#about"
                  className="neon-text text-light text-uppercase"
                >
                  About
                </a>
              </motion.li>
              <motion.li
                variantitem={variantitem}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a
                  href="#project"
                  className="neon-text text-light text-uppercase"
                >
                  portfolio
                </a>
              </motion.li>
              <motion.li
                variantitem={variantitem}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a
                  href="#contact"
                  className="neon-text text-light text-uppercase"
                >
                  Contact
                </a>
              </motion.li>
            </motion.ul>
          </motion.div>
          <div className="px-2 mt-1"></div>
        </motion.nav>
      </header>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
    </>
  );
};

export default Navbar;
