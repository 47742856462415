import React, { useState, useEffect } from "react";

const AutomaticLetterTyping = () => {
  const words = [
    "PHP",
    "Laravel",
    "Js",
    "jQuery",
    "React",
    "SQL",
    "Bootstrap",
    "Github",
    "css3",
  ];
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentWord = words[currentIndex];

    const typingInterval = setInterval(() => {
      setDisplayedText((prevText) =>
        isDeleting
          ? prevText.slice(0, -1)
          : currentWord.slice(0, prevText.length + 1)
      );

      if (!isDeleting && displayedText.length === currentWord.length) {
        setIsDeleting(true);
      } else if (isDeleting && displayedText === "") {
        setIsDeleting(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    }, 150);

    return () => {
      clearInterval(typingInterval);
    };
  }, [currentIndex, displayedText, isDeleting, words]);

  return (
    <div className="d-flex">
      <div id="editor" className="text-primary">
        {displayedText}
      </div>
      <span id="cursor" className="text-primary">
        |
      </span>
    </div>
  );
};

export default AutomaticLetterTyping;
