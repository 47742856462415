import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import irc from "./img/irc.webp";
import ircmd from "./img/ircmd.webp";
import ircsm from "./img/ircsm.webp";
function Item() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  return (
    <motion.div
      ref={ref}
      className="m-auto col-md-3  mt-0 bg-transparent circle position-relative"
      drag
      dragConstraints={{
        top: -6,
        left: -6,
        right: 6,
        bottom: 0,
      }}
    >
      <motion.figure className="progress bg-transparent">
        <svg
          id="progress"
          className=""
          width="75"
          height="75"
          viewBox="0 0 100 100"
        >
          <circle cx="50" cy="50" r="30" pathLength="1" className="bg shadow" />
          <motion.circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            className="indicator"
            style={{ pathLength: scrollYProgress }}
          />
        </svg>
      </motion.figure>
    </motion.div>
  );
}
const Project = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });
  const scale = useTransform(
    useScroll({ target: ref }).scrollYProgress,
    [0, 0.1],
    [0.9, 1]
  );
  return (
    <motion.div className="px-4 position-relative" id="project">
      <hr />
      <div className="">
        <span className="text-primary text-uppercase">
          <b>Projects</b>
        </span>
      </div>
      <div className="mt-3 d-flex justify-content-center w-100 py-5">
        <motion.div ref={ref} className="col-md-9 col-12 d-flex row">
          <Item />
          <motion.div className="col-md-9 col-12 shadow bg-white rounded-3">
            <div className="row">
              <div className="col-12 mb-2">
                <motion.img
                  src={irc}
                  alt=""
                  className="img-fluid rounded-3 shadow"
                  whileTap={{ scale: 1.1 }}
                  transition={{ duration: 0.1 }}
                  style={{ scale }}
                ></motion.img>
              </div>
              <div className="col-6 mb-2">
                <motion.img
                  src={ircmd}
                  alt=""
                  className="img-fluid rounded-3 shadow"
                  whileTap={{ scale: 1.1 }}
                  transition={{ duration: 0.1 }}
                  style={{ scale }}
                ></motion.img>
              </div>
              <div className="col-6 mb-2">
                <motion.img
                  src={ircsm}
                  alt=""
                  className="img-fluid rounded-3 shadow"
                  whileTap={{ scale: 1.1 }}
                  transition={{ duration: 0.1 }}
                  style={{ scale }}
                ></motion.img>
              </div>
              <div className=" p-4">
                <span className="text-primary fs-5 fs-md-4 fs-lg-3">
                  <b>Automobile IRC</b>
                </span>
                <div className="">
                  <span className="text-primary">
                    <b>Skills:-</b>
                  </span>
                  <span>
                    <b>Bootstrap,PHP,jQuery,SQL,JS and etc.</b>
                  </span>
                  <div className="d-flex justify-content-end">
                    <motion.a
                      href="https://vikasbishnoi.info/project/ircproject"
                      className="text-primary"
                    >
                      <b>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                          />
                        </svg>
                      </b>
                    </motion.a>
                  </div>
                  <p className="py-3">
                    <span className="text-primary">
                      <b>**Online Appointments:**</b>
                    </span>{" "}
                    Clients can easily schedule appointments for automobile
                    repairs through the website. The intuitive booking system
                    allows users to select the preferred date and time for their
                    service. <br />
                    <span className="text-primary">
                      <b>**Partner Request Submission:**</b>
                    </span>
                    Workshop partners can submit service requests directly
                    through the platform. This feature streamlines the
                    communication process between the workshop and its partners,
                    ensuring efficient collaboration. <br />
                    <span className="text-primary">
                      <b>**Appointment Tracking with Progress Bar:**</b>
                    </span>{" "}
                    Clients can track the progress of their appointments through
                    a visually informative progress bar. This feature provides
                    transparency and keeps clients informed about the status of
                    their vehicle repairs. <br />
                    <span className="text-primary">
                      <b>**Admin and Workshop Manager Access:**</b>
                    </span>
                    Authorized personnel, including administrators and workshop
                    managers, have access to a dedicated dashboard. This
                    dashboard allows them to view and manage all appointments,
                    update statuses, and efficiently coordinate the workflow.{" "}
                    <br />
                    <span className="text-primary">
                      <b> **Workshop Search Functionality:**</b>
                    </span>
                    Clients can easily search for workshops based on location,
                    services offered, or other relevant criteria. This feature
                    enhances user convenience and helps them find the most
                    suitable repair facility. <br />{" "}
                    <span className="text-primary">
                      <b>**Email Notifications:**</b>
                    </span>
                    Automatic email notifications are sent to clients,
                    administrators, and workshop managers when a new appointment
                    or partner request is submitted. This ensures that all
                    stakeholders are promptly informed and can take necessary
                    actions. <br />{" "}
                    <span className="text-primary">
                      <b>**Status Update Notifications:**</b>
                    </span>{" "}
                    Clients receive email notifications whenever there is a
                    status update on their appointment. This includes any
                    changes made by the admin or workshop manager, providing
                    real-time communication and transparency.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
      <hr />
    </motion.div>
  );
};

export default Project;
