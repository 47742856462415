const Footer = () => {
  return (
    <>
      <div className="bg-primary d-flex justify-content-center p-3 flex-column">
        <span className="text-light text-center">
          <b>Inprogress</b>
        </span>
        <div className="text-center">
          <span className="text-light">All Right Reseverd&copy;2024</span>
        </div>
      </div>
    </>
  );
};
export default Footer;
